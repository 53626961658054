import { defineStore } from 'pinia';
import productImage from '/images/product_categories/OTROS.png';
import { useStoresStore } from '@/stores/apps/store';

// project imports
import axios from '@/utils/axios';
// types
import type { ProductStateProps, Products } from '@/types/apps/EcommerceType';
import { recategorizeData, categoriesImages } from '@/types/apps/EcommerceType';
import { filter, map } from 'lodash';


export const useEcomStore = defineStore({
    id: 'eCommerce',
    state: (): ProductStateProps => ({
        cart: [],
        price: 0,
        subtotal: 0,
        discount: 0,
        discount_card: 0,
        taxes: 0,
        total: 0,
        total_qty: 0,
        activeCard: null,
        paymentMethod: null,
        hasRestriction: false,
        hasPriceRestriction: false,
        startTime: null,
    }),
    getters: {
        getTotals(state) {
            return {
                total: state.total,
                subtotal: state.subtotal,
                discount: state.discount,
                discount_card: state.discount_card,
                taxes: state.taxes,
                qty: state.total_qty,
            }
        },
        getCart(state) {
            return state.cart;
        },
        getTotalQty(state) {
            return state.total_qty;
        }
    },
    actions: {
        // Fetch Customers from action
        async fetchProducts() {
            try {
                const data = await axios.get('/api/products/list');
                this.products = data.data;
            } catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async updateItem(item: Products){
            try {
                const updatedProduct = await this.fetchProduct(item, true);
                Object.assign(item, updatedProduct, {
                    added: new Date() 
                });
            } catch (err) {
                this.deleteProduct(item.pk)
            }
        },
        async validateProducts(){
            const today = new Date().toISOString().split('T')[0]// .replace(/\D/g, '');
            for (const item of this.cart) {
                if ((typeof item.added === 'object' ? item.added.toISOString() : item.added).split('T')[0] !== today) {
                    await this.updateItem(item);
                }
            }
            return this.cart
        },
        // Fetch product
        async fetchProduct(product: string | number | Products, returnItem?: boolean, quantity?: number) {
            // console.log("Fetch Product:", product, typeof product)
            if (typeof product === 'undefined'){
                console.error("Product is undefined!!", typeof product, product)
                // TODO: Mensaje de error advirtiendo que no llegó el item (esto no debería suceder)
                throw new Error('Product is undefined!');
            } else if (typeof product === 'string' || typeof product === 'number') {
                if (typeof product === 'string' && product.trim() === ''){
                    console.error("Product is empty!!", typeof product, product)
                    throw new Error('Product is empty!');
                }
                let founded = false;
                const updateCart = map(this.cart, (item: any) => {
                    if (item.barcode === product && item.unit !== 'KG') {
                        founded = true;
                        if (returnItem){
                            return item;
                        }
                        return {
                            ...item,
                            qty: item.qty + 1
                        };
                    }
                    return item;
                });
                if (founded) {
                    this.cart = updateCart;
                    this.updateTotals()
                    return true;
                }
            }
            const baseUrl = useRuntimeConfig().public.apiBase;
            const storeId = useStoresStore().selectedStore.id;
            try {
                let response = await fetchWrapper.get(`${baseUrl}/api/v1/management/stores/${storeId}/products/${typeof product === 'string' || typeof product === 'number' ? product : product.barcode}?_=${new Date().getTime()}`);
                if (response){
                    const findCategory = recategorizeData.find(data => data.category === response.category)
                    const newCategory = findCategory && findCategory.group ? findCategory.group : response.category
                    const findImage = categoriesImages.find(data => data.category === newCategory)
                    const categoryImage = findImage && findImage.image ? findImage.image : productImage
                    // if (response.ean === '7509552848076') response.discount = 0 // temp hardcoded discount
                    const productFound = {
                            id: response.id,
                            sku: response.sku,
                            barcode: response.ean,
                            readed_code: response.ean,
                            name: response.name,
                            categories: [findCategory?.group],
                            price: Math.round(parseFloat(response.price)),
                            offer_price: Math.round(parseFloat(response.discount) > 0 ? parseFloat(response.price) * (1 - (parseFloat(response.discount) / 100)) : 0),
                            offer_card_price: Math.round((parseFloat(response.discount_card) - parseFloat(response.discount)) > 0 ? parseFloat(response.price) * (1 - ((parseFloat(response.discount_card) - parseFloat(response.discount)) / 100)) : 0),
                            qty: quantity ?? 1,
                            tax: parseFloat(response.iva),
                            unit: response.und.toUpperCase().trim(),
                            discount: parseFloat(response.discount) ?? 0,
                            discount_card: parseFloat(response.discount_card) ?? 0,
                            age_restriction: response.age_restriction ?? false,
                            image: categoryImage,
                            added: new Date()
                        };
                    if (typeof product === 'string' || typeof product === 'number') {
                        if (returnItem){
                            return productFound;
                        }
                        this.addToCart(productFound);
                    } else {
                        throw new Error('Product typeof unrecognized: ' + typeof product);
                        // return productFound
                    }
                } else {
                    throw new Error('Product not found');
                }
            } catch(e){
                // PESO VARIABLE:
                let barcode = typeof product === 'string' || typeof product === 'number' ? product.toString() : product.barcode;
                // NOTE: '10' para productos de prueba de Snapei:
                if (typeof barcode === 'string' && ['21','27','28','29'].includes(barcode.substring(0,2)) ) {
                    let newBarCode = ['21','27','28','29'].includes(barcode.substring(0,2))? barcode.substring(0, 7) : barcode.substring(0, 6);
                    let quantity = parseFloat(barcode.substring(barcode.length - 6, barcode.length - 4) + '.' + barcode.substring(barcode.length - 4, barcode.length -1 ));
                    let response = await fetchWrapper.get(`${baseUrl}/api/v1/management/stores/${storeId}/products/${newBarCode}`);
                    if (response){
                        const findCategory = recategorizeData.find(data => data.category === response.category)
                        const newCategory = findCategory && findCategory.group ? findCategory.group : response.category
                        const findImage = categoriesImages.find(data => data.category === newCategory)
                        const categoryImage = findImage && findImage.image ? findImage.image : productImage
                        // if (response.ean === '7509552848076') response.discount = 0 // temp hardcoded discount
                        const productFound = {
                                id: response.id,
                                sku: response.sku,
                                barcode: response.ean,
                                readed_code: barcode,
                                name: response.name,
                                categories: [findCategory?.group],
                                price: Math.round(parseFloat(response.price)),
                                offer_price: Math.round(parseFloat(response.discount) > 0 ? parseFloat(response.price) * (1 - (parseFloat(response.discount) / 100)) : 0),
                                offer_card_price: Math.round((parseFloat(response.discount_card) - parseFloat(response.discount)) > 0 ? parseFloat(response.price) * (1 - ((parseFloat(response.discount_card) - parseFloat(response.discount)) / 100)) : 0),
                                qty: quantity,
                                tax: parseFloat(response.iva),
                                unit: response.und.toUpperCase().trim(),
                                discount: parseFloat(response.discount) ?? 0,
                                discount_card: parseFloat(response.discount_card) ?? 0,
                                age_restriction: response.age_restriction ?? false,
                                image: categoryImage,
                                added: new Date()
                            };
                        console.info("productFound!", productFound)
                        if (typeof product === 'string' || typeof product === 'number') {
                            if (returnItem){
                                return productFound;
                            }
                            this.addToCart(productFound)
                        } else {
                            throw new Error('Product typeof unrecognized: ' + typeof product);
                            // return productFound
                        }
                    } else {
                        // TODO: Mensaje de error advirtiendo que no se encontró el item buscado
                        console.error("Product not found1: ", barcode.toString())
                        throw new Error('Product not found');
                    }
                } else {
                    // TODO: Mensaje de error advirtiendo que no se encontró el item buscado
                    console.error("Product not found2: ", product)
                    throw new Error('Product not found');
                }
            }
        },
        setActiveCard(card?: object){
            // console.log("activeCard: ", card)
            this.activeCard = card;
        },
        setStartTime(startTime: Date){
            this.startTime = startTime;
        },
        setPaymentMethod(paymentMethod?: string){
            this.paymentMethod = paymentMethod;
            if (paymentMethod === 'Other'){
                this.activeCard = null
            }
        },
        async addToCart(item: any) {
            const product = { ...item, pk: this.cart.length + 1 };
            this.cart = [...this.cart, product];
            this.updateTotals()
            if (item.age_restriction){
                this.hasRestriction = true;
            }
            return product
        },
        //qty
        async incrementQty(productPk: number) {
            let foundProduct = null;
            const updateCart = map(this.cart, (product: any) => {
                if (product.pk === productPk) {
                    foundProduct = product;
                    return {
                        ...product,
                        qty: product.qty + 1
                    };
                }
                return product;
            });
            this.cart = updateCart;
            this.updateTotals()
            return foundProduct;
        },
        //qty
        async decrementQty(productPk: any) {
            let foundProduct = null;
            const updateCart = map(this.cart, (product: any) => {
                if (product.pk === productPk) {
                    foundProduct = product;
                    return {
                        ...product,
                        qty: product.qty - 1
                    };
                }
                return product;
            });
            this.cart = updateCart;
            this.updateTotals()
            return foundProduct;
        },

        async updateTotals() {
            let subtotal = 0;
            let discount = 0;
            let discount_card = 0;
            let taxes = 0;
            let total_qty = 0;
            let has_restriction = false;
            let price_restriction = false;

            if (Object.keys(useStoresStore().selectedStore).length === 0){
                this.subtotal = subtotal;
                this.discount = discount;
                this.discount_card = discount_card;
                this.taxes = 0;
                this.total = 0;
                this.total_qty = total_qty;
                this.hasRestriction = has_restriction;
                this.hasPriceRestriction = price_restriction
                return;
            }
            let expensive_restriction = useStoresStore().restrictions.find(x=>x.reason=='Expensive_Cost')?.price_limit ?? 0;
        
            // Recorremos el array `cart` una sola vez
            await this.cart.forEach(async (product: any) => {
                // Calcular subtotal
                const sale_price_total = Math.round(product.price * product.qty);
                subtotal += sale_price_total;
        
                const offer_price_total = Math.round((product.offer_price ?? product.price) * product.qty);
                discount += product.offer_price  > 0 ? Math.round(sale_price_total - offer_price_total) : 0;
        
                const offer_card_price_total = Math.round((product.offer_card_price ?? product.price) * product.qty);
                discount_card += product.offer_card_price > 0 ? Math.round(sale_price_total - offer_card_price_total) : 0;
        
                // Calcular impuestos
                taxes += Math.round((sale_price_total * (1 + (product.tax / 100))) - sale_price_total);
        
                // Calcular total_qty si no pertenece a la categoría 'BOLSAS'
                if (!product.categories.includes('BOLSAS')) {
                    total_qty += product.unit.toUpperCase().trim() === 'UND' ? product.qty : 1;
                }
                if (product.age_restriction && !has_restriction){
                    has_restriction = true;
                }
                if (expensive_restriction > 0 && product.price >= expensive_restriction && !price_restriction){
                    price_restriction = true;
                    product.price_restriction = true;
                }
            });

        
            // Asignamos los valores a las propiedades correspondientes
            this.subtotal = subtotal;
            this.discount = Math.round(discount);
            this.discount_card = Math.round(discount_card);
            this.taxes = Math.round(subtotal - discount - taxes);
            this.total = Math.round(subtotal - discount);
            this.total_qty = total_qty;
            this.hasRestriction = has_restriction;
            this.hasPriceRestriction = price_restriction
        },
        
        // delete Product
        async deleteProduct(productPk: any) {
            // console.log("Entra a deleteProduct()");
            const updateCart = filter(this.cart, (p:any) => p.pk !== productPk);
            this.cart = updateCart;
            if (this.cart.length === 0){
                this.deleteCart('deleteItem');
            } else {
                this.updateTotals()
            }
        },
        // delete Cart
        async deleteCart(from="") {
            // console.log(`Entra a deleteCart(from=${from})`);
            this.cart = [];
            if (from !== 'deleteItem'){
                useInvoiceStore().setInvoice('');
                useInvoiceStore().setPaymentStatus('');
                this.startTime = new Date();
            }
            this.updateTotals()
        },
        findProductOnCart(code: string){
            return this.cart.find((product: any) => product.barcode === code);
        },
        async createPayment(payload:any) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            return fetchWrapper.post(`${baseUrl}/api/v1/payments/mercado-pago/`, payload )
                .then((data)=> {
                    console.info(data)
                    return { message: data.message.message ?? data.message, status: data.status ?? data.message.status, id: data.message.id }
                })
                .catch(error => {
                    throw error;
                })
        },

        async getTimers() {
            const startTime = this.startTime ? new Date(this.startTime) : new Date();
            const now = new Date();
            const timeInteraction = (now - startTime) / 1000;
            let timeDiffFirstProduct = 0;
            let timeDiffLastProduct = 0;
            let averageInterval = 0;
            
            let products = JSON.parse(JSON.stringify(this.cart)).filter((product:Products) => !product.categories.includes('BOLSAS'));
            if (products.length > 0){
                await products.forEach(async (product:Products)  => {
                    product.added = (typeof product.added === 'object' ? product.added : new Date(product.added)); // Convertir string ISO a Date
                });
                await products.sort(async (a, b) => a.added.getTime() - b.added.getTime()); 
                const firstProduct = products[0];  
                const lastProduct = products[products.length - 1]; 
                timeDiffFirstProduct = (firstProduct.added.getTime() - startTime.getTime()) / 1000;
                const timeDiffLastProduct = (now.getTime() - lastProduct.added.getTime()) / 1000;
    
                const timeIntervals = await Promise.all(products.map((product, index) => {
                    if (index === 0) return 0; // No hay intervalo para el primer producto
                    return (new Date(product.added).getTime() - new Date(products[index - 1].added).getTime()) / 1000;
                }));
                averageInterval = timeIntervals.slice(1).reduce((a, b) => a + b, 0) / (products.length - 1);
            }
            

            return {
                interaction_time: Math.round(timeInteraction),
                first_product_time: Math.round(timeDiffFirstProduct),
                last_product_time: Math.round(timeDiffLastProduct),
                average_product_time: Math.round(averageInterval ?? 0)
            }
        },
    },
    persist: {
        storage: persistedState.sessionStorage,
    },
});
