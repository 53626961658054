import { defineStore } from "pinia";
// import { processSQLiteFile } from "@/utils/SQLiteToIndexedDB";
import { useEcomStore } from "@/stores/apps/eCommerce";
import { useUsersStore } from "@/stores/users";
import { FacebookLogin } from '@capacitor-community/facebook-login';


export const useAuthStore = defineStore({
    id: "auth",
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        appSession: {},
        userSession: {},
        tutorialsList: ['none','gps','scan','profile','pay'],
        toShowTutorial: 0,
        returnUrl: null,
    }),
    actions: {
        async validate(force: boolean = false) {
            if (!this.appSession) return false;
            let parsedDatetime = new Date(Date.parse(this.appSession.expires));
            let actualDatetime = new Date();
            let datetimesDiff = parsedDatetime.getTime() - actualDatetime.getTime();
            // console.log("validate() => datetimesDiff: ", datetimesDiff);
            if (datetimesDiff / (1000 * 60) <= 0) {
                await this.logout();
                return false;
            } else {
                // TODO: Lógica de expiración del token:
                /* else if (((datetimesDiff / (1000 * 60)) < 5 && (datetimesDiff / (1000 * 60)) > 0) || force) {
                    const baseUrl = useRuntimeConfig().public.apiBase;
                    return await fetchAppWrapper.post(`${baseUrl}/api-auth/token-refresh/`, { 'refresh': this.appSession.refresh })
                    .then((response) => {
                        this.appSession.refresh = response.refresh
                        this.appSession.expires = new Date(actualDatetime.getTime() + (24 * 60 * 60 * 1000));
                        return true;
                    })
                    .catch(() => {
                        this.userSession = null;
                        return false;
                    })
                */
                return true;
            }
        },
        async appLogin() {
            try {
                const baseUrl = useRuntimeConfig().public.apiBase;
                const email = useRuntimeConfig().public.apiUser;
                const password = useRuntimeConfig().public.apiPass; // N/A

                let isExpired = false;
                if (Object.keys(this.appSession).length > 0) {
                    const dateExpiration = new Date(this.appSession.expires)
                    isExpired = dateExpiration <= new Date();
                }
                // TODO: NO hacerlo por expiración al api-auth, sino hacerlo con expiración con el refresh-token
                if (Object.keys(this.appSession).length === 0 || isExpired) {
                    let responseAuth = await fetchAppWrapper.post(
                        `${baseUrl}/api-auth/`,
                        { email, password }
                    ).catch(() => {
                        this.appSession = {};
                        return false;
                    });
                    // localStorage.setItem("auth.appSession", JSON.stringify(responseAuth));
                    this.appSession = responseAuth;
                }
            } catch (e) {
                console.error(e);
                throw e;
                return Promise.reject(e);
            }
        },
        async userLogin(email: string, password: string, coordinates: string) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                const response = await fetchAppWrapper.post(
                    `${baseUrl}/api-auth/`, 
                    { email, password, coordinates }
                );
                // console.info("response: ", response);
                if (response) {
                    // console.log("into response")
                    this.userSession = response;
                    // store user details and jwt in local storage to keep user logged in between page refreshes
                    await useUsersStore().getUserCreditCards();
                    // await useUsersStore().setCreditCards(this.userSession?.user?.thirdparty?.credit_card || []);
                    await useStoresStore().getStores();
                    navigateTo("/");
                }
            } catch (e) {
                console.error(e);
                return Promise.reject(e);
            }
        },
        async userRegisterPetition(payload:any) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                const response = await fetchAppWrapper.post(
                    `${baseUrl}/base/users/register/`,
                    payload
                );
                console.info("response register: ", response);
                if (response) {
                    await this.userLogin(payload.email, payload.password, payload.coordinates)
                }
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async userUpdatePetition(payload: Object, id: Number, redirectToHome: boolean = false) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                const response = await fetchAppWrapper.put(
                    `${baseUrl}/base/users/${id}/`,
                    payload
                );
                // console.info("response: ", response);
                if (response) {
                    let result = {
                        user: {}
                    }
                    let userKeys = JSON.parse(sessionStorage.getItem("auth") ?? "{}")
                    userKeys.user = response
                    userKeys.user.user = response.user_info
                    userKeys.user.thirdparty = response.thirdparty
                    this.userSession = userKeys?.userSession;
                    // store user details and jwt in local storage to keep user logged in between page refreshes
                    if (redirectToHome){
                        navigateTo("/");
                    }
                }
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async userUpdatePreferences(payload: Object, id: Number, redirectToHome?: boolean) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                const response = await fetchAppWrapper.patch(
                    `${baseUrl}/base/user-preferences/${id}/`,
                    payload
                );
                // console.info("response: ", response);
                if (response) {
                    this.userSession.user.preferences = payload;
                    // store user details and jwt in local storage to keep user logged in between page refreshes
                    if (redirectToHome){
                        navigateTo("/");
                    }
                }
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async userChangePassword(payload: Object, id: Number, redirectToHome?: boolean) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                const response = await fetchAppWrapper.put(
                    `${baseUrl}/base/users/${id}/set_password/`,
                    payload
                );
                // console.info("response: ", response);
                if (response) {
                    // store user details and jwt in local storage to keep user logged in between page refreshes
                    if (redirectToHome){
                        navigateTo("/");
                    }
                }
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async userRegister(payload: any, redirectToHome: boolean = false) {
            const baseUrl = useRuntimeConfig().public.apiBase;
            // console.info(payload);
            // console.info(payload.email);
            let userPayload = {}
            if ((!payload.gmail && !payload.facebook) || payload.id) {
                userPayload = {
                    username: `${payload.email ? payload.email.trim().toLowerCase() : ''}`,
                    email: `${payload.email.trim().toLowerCase()}`,
                    password: `${payload.password ? payload.password : null}`,
                    repeat_password: `${payload.password ? payload.password : null}`,
                    app_sections_users: [],
                    social_auth: false,
                    // TODO: tipo identificación variable
                    identification_type: payload.identificationType? `${payload.identificationType}` : "C", 
                    nit: `${payload.identificationNumber.trim()}`,
                    social_reason: `${payload.socialReason ? payload.socialReason.trim().toUpperCase() : ""}`,
                    firstName: `${payload.firstName ? payload.firstName.trim().toUpperCase() : ""}`,
                    middleName: `${payload.middleName ? payload.middleName.trim().toUpperCase() : ""}`,
                    firstSurname: `${payload.firstSurname ? payload.firstSurname.trim().toUpperCase() : ""}`,
                    secondSurname: `${payload.secondSurname ? payload.secondSurname.trim().toUpperCase() : ""}`,
                    born_date: payload.bornDate ?? null,
                    ind_tipo_tercero: payload.identificationType != "N" ? 1 : 2,
                    contact: `${payload.firstName ?? "".trim()} ${payload.middleName ?? "".trim().toUpperCase()}`,
                    address: `${payload.address ? payload.address.trim().toUpperCase() : ""}`,
                    // phone: `${payload.phone ? payload.phone.trim() : ""}`,
                    // email: `${payload.email.trim().toLowerCase()}`,
                    cellphone: `${payload.cellphone.trim()}`,
                    genre: `${payload.genre ? payload.genre : ''}`,
                    preferences: payload.preferences ? payload.preferences : {}
                }
                if (payload.id){
                    delete userPayload.password;
                    delete userPayload.repeat_password;
                }
            } else {
                userPayload = {
                    username: `${payload.username ? payload.username : payload.email ? payload.email.trim().toLowerCase() : ''}`,
                    email: `${payload.email.trim().toLowerCase()}`,
                    password: `${payload.sub ? payload.sub : payload.id_facebook}`,
                    repeat_password: `${payload.sub ? payload.sub : payload.id_facebook}`,
                    app_sections_users: [],
                    sub: `${payload.sub ? payload.sub : null}`, 
                    id_facebook: `${payload.id_facebook ? payload.id_facebook : null}`,
                    social_auth: true,
                    data_social_auth: `${payload.gmail ? 'GMAIL' : 'FACEBOOK'}`,
                    picture: `${payload.picture? payload.picture : null }`,
                    preferences: { // all on true by default:
                        terms_and_conditions: true,
                        privacy_policy: true,
                        email_subscription: true,
                        receive_whatsapp_info: true,
                        receive_email_info: true,
                        receive_sms_info: true,
                        push_notifications_info: true
                    }
                }
            }

            try {
                if (!payload.id) {
                    if (userPayload.social_auth) {
                        await useUsersStore().getUserForEmail(userPayload.email).then(async (data) => {
                            // console.error("data:", data, "userPayload:", userPayload)
                            if (data && data.results && data.results.length > 0) {
                                this.userUpdatePetition(userPayload, data.results[0].id, false)
                                await this.userLogin(userPayload.email, userPayload.password, payload.coordinates)
                            } else {
                                await this.userRegisterPetition(userPayload)
                            }
                        }).catch((e) => {
                            return Promise.reject(e);
                        })
                    } else if (!userPayload.social_auth) {
                        await this.userRegisterPetition(userPayload)
                    }
                } else {
                    await this.userUpdatePetition(userPayload, payload.id, redirectToHome)
                }
            } catch (e) {
                return Promise.reject(e);
            }
        },

        async userRemoveRegistry() {
            const baseUrl = useRuntimeConfig().public.apiBase;
            try {
                const response = await fetchWrapper.delete(
                    `${baseUrl}/base/users/destroy_account/`
                );
                console.info("response destroy: ", response);
                return this.logout('accountRemoved&email=' + this.userSession?.user?.email);
            } catch (e) {
                return Promise.reject(e);
            }
        },

        async logout(type: string | undefined | null = null) {
            // console.log("pasa por logout!");
            // Log out from Facebook:
            try {
                const result = await FacebookLogin.getCurrentAccessToken()
                if (result.accessToken) {
                    await FacebookLogin.logout();
                }
            } catch(e){
                // console.info("Is not Facebook authenticated")
            }
            this.userSession = null;
            if (type !== "Unauthorized") {
                sessionStorage.clear();
                // sessionStorage.setItem("colorScheme", "");
                // sessionStorage.setItem("darkMode", "");
                // sessionStorage.setItem("menuMode", "");
            }
            sessionStorage.removeItem("auth.appSession");
            sessionStorage.removeItem("auth.userSession");
            sessionStorage.removeItem("stores.allStores");
            sessionStorage.removeItem("stores.selectedStore");
            await useEcomStore().deleteCart("auth.logout()");
            useStoresStore().cleanStores();
            await navigateTo("/auth/login?type=" + type);
        },
        async setUserPaymentsGatewayCustomerId(id:string){
            this.userSession.user.thirdparty.id_customer_payments_gateway = id;
        },
        async setToShowTutorial(value:number){
            this.toShowTutorial = value
        }
    },
    getters: {
        isLogged(state) {
            return state.userSession && Object.keys(state.userSession).length > 0
                ? true
                : false;
        },
        userName(state) {
            return state.userSession && Object.keys(state.userSession).length > 0
                ? `${state.userSession.user.thirdparty.first_name}`
                : "";
        },
        isThirdpartyFilled(state) {
            const validation = state.userSession &&
                state.userSession.user && 
                state.userSession.user.thirdparty  &&
                state.userSession.user.thirdparty.first_name &&
                state.userSession.user.thirdparty.first_surname &&
                state.userSession.user.thirdparty.cellphone && state.userSession.user.thirdparty.cellphone.length > 7 &&
                state.userSession.user.thirdparty.email && state.userSession.user.thirdparty.email.length > 5 &&
                state.userSession.user.thirdparty.identification_type &&
                state.userSession.user.thirdparty.born_date ? 
                true : false; 
            return validation;
        },
        getUserGroups(state){
            return state.userSession && 
                    state.userSession.user && 
                    state.userSession.user.groups_detail.map((group:any) => group.name) || [];
        },
        isSuperUser(state){
            return state.userSession && 
                    state.userSession.user && 
                    state.userSession.user.is_superuser ?
                    true : false;
        },
        showTutorial(state){
            return state.tutorialsList[state.toShowTutorial];
        }
    },
    persist: {
        storage: persistedState.sessionStorage,
    },
});
