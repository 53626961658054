export default defineNuxtRouteMiddleware(async(to, from)  => {

  // console.log("Validating...")
  const tokenValidation = await useAuthStore().validate();
  // console.log("loggedIn: ", useAuthStore().isLogged, " to.fullPath: ", to.fullPath, "appInfo: ", useAuthStore().appInfo, " appSession: ", useAuthStore().appSession);
  if (!useAuthStore().appSession || !useAuthStore().appInfo || !useAuthStore().isLogged) {
    // console.log("pasa por 1a: ", !useAuthStore().appSession, !useAuthStore().appInfo, !useAuthStore().isLogged)
    try {
      await useAuthStore().appLogin()
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  
  if ((!tokenValidation ||!useAuthStore().isLogged) && !to.fullPath.startsWith('/auth')) {
    // console.log("pasa por 2a")
    return navigateTo('/auth/login')
  } else if (useAuthStore().isLogged && to.fullPath === '/auth/login'){
    // console.log("pasa por 3a")
    return navigateTo('/')
  } 
})
