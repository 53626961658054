import {defineRule, configure} from 'vee-validate';
import * as Rules from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n'
import es from '@vee-validate/i18n/dist/locale/es.json';
import en from '@vee-validate/i18n/dist/locale/en.json';
import messages from "@/utils/locales/messages";

export default defineNuxtPlugin(() => {
  try {
    for (const [ruleName, rule] of Object.entries(Rules)) {
      if (ruleName && rule && ruleName !== 'all') {
        defineRule(ruleName, rule);
      }
    }
  } catch (e) {
    console.error(e);
  }

  defineRule('strongPassword', value => {
    if (!value || !value.length) {
      return true;
    }
    if (!/^(?=.[0-9])(?=.[!@#$%^&])[a-zA-Z0-9!@#$%^&]{8,22}$/.test(value)) {
      return 'Requiere una contraseña más fuerte';
    }
    return true;
  });
});

localize({en, es});

configure({
  validateOnBlur: true,
  validateOnChange: true,
  validateOnInput: true,
  validateOnModelUpdate: true,
  generateMessage: localize({
    es, en,
  }),
  generateMessage: localize(
    { 'es': { names: messages.es.fields }}, 
    { 'en': { names: messages.en.fields }}
  ),
});

setLocale('es');